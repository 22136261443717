import React from 'react'
import { Container, Typography } from '@mui/material'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import Layout from '../components/layout'

const PrivacyPage = () => {
    const {
        site: {
            siteMetadata: { siteUrl, siteTitle },
        },
    } = useSiteMetadata()

    const title = `プライバシーポリシー | ${siteTitle}`
    const description = `プライバシーポリシー`
    return (
        <Layout title={title} description={description}>
            <Container maxWidth="md" sx={{ pt: { xs: 3, sm: 6 } }}>
                <Typography gutterBottom variant="h1" sx={{ mb: 4 }}>
                    プライバシーポリシー
                </Typography>

                <Typography variant="h3">お客様から取得する情報</Typography>
                <Typography
                    component="pre"
                    variant="body1"
                    sx={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}
                >{`
技術ブログまとめましたは、お客様から以下の情報を取得します。
・Cookie(クッキー)を用いて生成された識別情報
・OSが生成するID、端末の種類、端末識別子等のお客様が利用するOSや端末に関する情報
・滞在時間、閲覧履歴等の当サイトにおけるお客様の行動履歴
                `}</Typography>

                <Typography variant="h3">お客様の情報を利用する目的</Typography>
                <Typography
                    component="pre"
                    variant="body1"
                    sx={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}
                >{`
技術ブログまとめましたは、お客様から取得した情報を、以下の目的のために利用します。

・お客様の当サイトサービスの利用履歴を管理するため
・当サイトサービスにおけるお客様の行動履歴を分析し、当サイトサービスの維持改善に役立てるため
・広告の配信、表示及び効果測定のため
・お客様からのお問い合わせに対応するため
・以上の他、当サイトサービスの提供、維持、保護及び改善のため

安全管理のために講じた措置
当サイトが、お客様から取得した情報に関して安全管理のために講じた措置につきましては、末尾記載のお問い合わせ先にご連絡をいただきましたら、法令の定めに従い個別にご回答させていただきます。
                `}</Typography>

                <Typography variant="h3">第三者提供</Typography>
                <Typography
                    component="pre"
                    variant="body1"
                    sx={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}
                >{`
技術ブログまとめましたは、お客様から取得する情報のうち、個人データ（個人情報保護法第２条第６項）に該当するものついては、あらかじめお客様の同意を得ずに、第三者（日本国外にある者を含みます。）に提供しません。
但し、次の場合は除きます。
・個人データの取扱いを外部に委託する場合
・その他、法律によって合法的に第三者提供が許されている場合
                `}</Typography>

                <Typography variant="h3">アクセス解析ツール</Typography>
                <Typography
                    component="pre"
                    variant="body1"
                    sx={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}
                >{`
技術ブログまとめましたは、お客様のアクセス解析のために、「Googleアナリティクス」を利用しています。
Googleアナリティクスは、トラフィックデータの収集のためにCookieを使用しています。トラフィックデータは匿名で収集されており、個人を特定するものではありません。
Cookieを無効にすれば、これらの情報の収集を拒否することができます。詳しくはお使いのブラウザの設定をご確認ください。

Googleアナリティクスについて、詳しくは以下からご確認ください。
　https://marketingplatform.google.com/about/analytics/terms/jp/
                `}</Typography>

                <Typography variant="h3">広告の配信</Typography>
                <Typography
                    component="pre"
                    variant="body1"
                    sx={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}
                >{`
技術ブログまとめましたは、Google及びそのパートナー（第三者配信事業者）の提供する広告を設置しています。
広告配信にはCookieを使用し、お客様が過去に当サイトやその他のサイトにアクセスした情報に基づいて広告を配信します。
Google やそのパートナーは、Cookieを使用することにより適切な広告を表示しています。

お客様は、以下のGoogleアカウントの広告設定ページから、パーソナライズ広告を無効にできます。
　https://adssettings.google.com/u/0/authenticated

また aboutads.info のページにアクセスし、パーソナライズ広告掲載に使用される第三者配信事業者のCookieを無効にすることもできます。

その他、GoogleによるCookieの取り扱い詳細については、以下のGoogleのポリシーと規約のページをご覧ください。
　https://policies.google.com/technologies/ads
                `}</Typography>

                <Typography variant="h3">プライバシーポリシーの変更</Typography>
                <Typography
                    component="pre"
                    variant="body1"
                    sx={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}
                >{`
技術ブログまとめましたは、必要に応じて、このプライバシーポリシーの内容を変更します。この場合、変更後のプライバシーポリシーの施行時期と内容を適切な方法により周知または通知します。
                `}</Typography>

                <Typography variant="h3">お問い合わせ</Typography>
                <Typography
                    component="pre"
                    variant="body1"
                    sx={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}
                >{`
情報の訂正、削除をご希望の場合は、以下のメールアドレスにご連絡ください。
e-mail
okdyy75+techblog-matome@gmail.com
                `}</Typography>

                <Typography
                    component="pre"
                    variant="body1"
                    sx={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}
                >{`
技術ブログまとめました

2022年05月08日 制定
                `}</Typography>
            </Container>
        </Layout>
    )
}

export default PrivacyPage
